import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const header_image = require("../../../assets/img/blogs/kaeq_blog_header.png");
const section_1 = require("../../../assets/img/blogs/kaeq_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/kaeq_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/kaeq_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/kaeq_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/kaeq_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/kaeq_blog_img_6.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Implement Knowledge AI for Automating Repetitive Employee Queries"
        description="Implement Knowledge AI to automate common employee queries using the Workativ conversational AI platform, the easiest way to harness the power of Generative AI or LLM."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt kaeq_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to Implement
                    <span style={{ backgroundColor: "#FFFA7E" }} className="blog-text-color-padding">
                      Knowledge AI
                    </span>{" "}
                    for Automating Repetitive
                    <span style={{ backgroundColor: "#FFFA7E" }} className="blog-text-color-padding">
                      Employee Queries
                    </span>
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to Implement
                    <br />
                    <span style={{ backgroundColor: "#FFFA7E" }} className="blog-text-color-padding">
                      Knowledge AI
                    </span>{" "}
                    for
                    <br /> Automating Repetitive
                    <br />
                    <span style={{ backgroundColor: "#FFFA7E" }} className="blog-text-color-padding">
                      Employee Queries
                      <br />
                    </span>
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Challenges: Employee support limitations with traditional
                  KB searches.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Can large language models be a substitute for traditional
                  KB searches?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. What is Knowledge AI?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Introducing Knowledge AI search from Workativ
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How effective is Knowledge AI for employee support
                  enhancement?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. How to implement Knowledge AI with Workativ LLM-powered
                  conversational AI platform?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                ‘What’s the status of my request for a new laptop?’
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A help desk agent who has just joined a call is as clueless as
                you are.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s a long wait time for the requester until the agent searches
                for ticket history and returns with the correct status info.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What else?
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employees are less predictive about when they can have the
                  right device to work at their best.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee value is reduced.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Disengaged employees cost organizations lost productivity.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instantaneous, real-time, yet factual information is a tough
                challenge for organizations, forcing them to struggle to meet
                employee expectations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When thinking about employee resilience, tech-enabled capability
                building is the key to creating value for employees and
                businesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s a win-win for organizations when they focus on performance
                and people through tech investment. McKinsey & Co. predicts{" "}
                <a href="https://www.mckinsey.com/quarterly/the-five-fifty/five-fifty-employee-development-a-skills-based-approach?cid=other-eml-onp-mip-mck&hlkid=85f3fbae0d9249beb7ae7c24169b4e5d&hctky=14822067&hdpid=ffe42cd9-d1ff-41d5-972c-c6077c4d8f6e">
                  $1 billion in economic profit for organizations with a
                  perspective of employee resilience and development.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, organizations have long struggled to deploy the right
                tech-enabled capability and resorted to a predefined or
                rule-based chatbot to facilitate employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A chatbot with default replies can help with problems with known
                challenges 一 but with lots of effort from the users and help
                desk agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI and conversational AI can come together to speed up
                employee support, reduce the time to search for information and
                improve CSAT (employee satisfaction).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this article, we will talk about why it is time to switch to
                Knowledge AI for employee support rather than relying on regular
                chatbots for repetitive employee support and value addition.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs ">
                Challenges: Employee support limitations with traditional KB
                searches.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                How often do you look at the downsides of enterprise search
                systems or default reply-based chatbots?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Maybe never.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Information is scattered across various intrasystem and external
                databases. So, data lying in silos can be complex to find
                quickly and, more often, result in workspace productivity
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The pain points with an enterprise search system, such as
                chatbots, are strikingly significant.
              </p>
              <img src={section_1} className="blog_image_top_bt" alt="" />

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Incomplete insights into employee questions
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                KB articles are often limited in providing complete insights
                into employee questions.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-6 list-style-type-circle">
                <li className="ffont-section-normal-text-testimonials">
                  New information is often available but requires manual effort
                  to update the knowledge bases, a challenge for most
                  organizations with limited resources.
                </li>
                <li className="ffont-section-normal-text-testimonials">
                  Information surfaced in a question-answering window has a list
                  of suggestions in the form of articles, requiring users to
                  dedicate extended time for knowledge discovery.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  More time to build KBs
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Building knowledge bases for search systems or FAQs takes a huge
                chunk of time, bypassing the need to make changes to the
                existing content in KB articles and leading users to struggle to
                resolve problems, even with self-service functionality.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Less integration flexibility with content systems
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Traditional chatbots or enterprise search systems offer limited
                integration capabilities with content resources spread across
                enterprise-wide systems, hence limited knowledge discovery to
                offer the help employees need to resolve problems autonomously.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Limited problem-solving capability with built-in VAs
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Virtual agents or chatbots built within the service desk or help
                desk systems can offer service desk assistance but are limited
                to ticket creation and escalation to the various stages to tier
                agents. There is no straightforward or real-time autonomous help
                with many built-in chatbots.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Lack of personalized conversational experience
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Though employees want independent problem-solving capabilities,
                the human touch adds to a great conversational experience, most
                miss out on this opportunity with rudimentary chatbots or
                default-reply-based chatbots. With end-to-end conversations
                sounding interactive, users tend to adapt fast and connect more
                to solve the workspace problems autonomously.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Can large language models be a substitute for traditional KB
                searches?
              </h2>
              <img src={section_2} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Large language models are AI-powered natural language models
                with huge corpora of datasets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over-the-shelf LLMs such as ChatGPT or GPT-4 contain generic
                data to answer generic questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, when you combine your enterprise search systems or
                chatbots with LLMs, your employees can retrieve answers that
                everyone else can find for issues such as headphone sound
                troubleshooting, and application installation, to name a few.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Similar IT or employee support problems other enterprises have
                can fetch solutions to some length from LLM-powered chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Regarding specificity, meaning if users need a domain-specific
                solution to problems, LLMs alone cannot offer help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, An organization using a project management tool
                like Notion has specific data related to various sales
                operations, marketing, etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                An employee wants an update about a client’s project, say, a
                data migration project. Over-the-shelf LLM-powered chatbots can
                limit this expectation. On the other hand, LLMs integrate
                generic information, which can be wrongful, causing
                hallucinations and misinformation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI integration can be highly efficient to improve
                search functionalities and knowledge discovery to help with
                effective employee support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is Knowledge AI?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI, as the name suggests, refers to an information
                discovery capability built on top of generative artificial
                intelligence and conversational AI technologies to improve
                search performance through personalized and summarized knowledge
                discovery to enhance employee productivity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Often, structured FAQ-based knowledge falls short of employee
                support expectations to deliver help with common and repetitive
                questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI can fetch data from unstructured data grounded in
                KB articles within the platform and offer NLP responses to
                queries through intent detection, context, and entity
                extraction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI offers custom NLP responses beyond pre-defined
                rules or keyword-based search results compared to traditional
                search platforms or chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, Knowledge AI offers more personalized and
                conversational responses that help employees resolve their
                problems at scale.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Introducing Knowledge AI search from Workativ
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ brings Knowledge AI search integrations for its{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI platform
                </a>{" "}
                to allow users to leverage the benefits of large language model
                properties or generative AI capability to enhance the speed of
                capturing information, producing personalized and intent-based
                information for the repetitive and common user queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ provides a ChatGPT-like search experience that is more
                personalized and conversational for autonomous problem-solving
                in the enterprise setting.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                “Retrieving information from several knowledge bases or KB
                sources through connector-based integrations across disparate
                systems, such as SharePoint, Dropbox, Google Drive, Notion,
                etc., helps employees quickly find context-aware information and
                resolve common issues more efficiently”.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How effective is Knowledge AI for employee support enhancement?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                From a ChatGPT or LLM point of view, finding answers to queries
                is best for learning new skills or knowing something to solve a
                problem in an ongoing process.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As we said earlier, domain-specific issues need custom answers
                employees can use to automate and resolve problems
                independently.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI removes friction in the self-service capabilities,
                found with rule-based search systems or chatbots by tapping into
                various knowledge articles uploaded through Workativ or Internal
                KB, External KB, and Website KB.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It means knowledge search is not restricted to more than just a
                few article bases. Instead, it has an extensive language model
                database to augment the semantic search and deliver accurate and
                coherent generative answers to NLP-based common queries.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With Workativ Knowledge AI, users can remove the need to train
                the model as needed with custom solutions to be built with LLM
                models or GPT models from scratch.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Neither does it need fine-tuning nor prompt-engineering. All it
                takes is upload content to train, modify, or delete content so
                that users can always get up-to-date information and accelerate
                search performance via Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Also, Knowledge AI provides a closed domain to upload content
                and build your specific large language model to offer
                domain-specific answers with more robust security checks to
                avoid misinformation like the open-source training method,
                making it less vulnerable to hallucination and delivering biased
                and wrong information.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Take one example here. For security reasons, a user needs to
                know the VPN settings of his device while working from home. It
                could hamper his work if he doesn’t get the information in time.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A traditional search system could only provide articles about
                VPN setting issues. Unaware of which piece could provide an apt
                and accurate answer for the self-service, he might comb through
                lengthy articles and handle information in a silo.
              </p>
              <img src={section_3} className="blog_image_top_bt" alt="" />
              <p class="font-section-normal-text-testimonials line-height-18">
                However, Knowledge AI provides straightforward, accurate,
                summarized, and personalized versions of responses for any
                common and repetitive queries.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using Knowledge AI-powered self-service built in a{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  conversational AI chatbot,
                </a>{" "}
                a user can capture only necessary information presented in a
                summarized manner to help resolve the VPN setting issues.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to implement Knowledge AI with Workativ LLM-powered
                conversational AI platform?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ allows you to unlock the full potential of a no-code
                platform to enable you to build a conversational AI chatbot or
                FAQ-based bot by layering the properties of large language
                models or Generative AI through Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Implementing Knowledge AI KB is just a few clicks of a task for
                existing users. If you already use a chatbot or FAQ bot, click
                Knowledge AI search from the drop-down menu next to a dialog
                template, and it will be implemented.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                However, You need to build your KBs to gain the flexibility of a
                large language model in generating personalized answers to
                common queries.
              </p>
              <p class="font-section-normal-text-testimonials-medium color-black mb-0">
                Here are the prerequisites to develop your LLM-powered Knowledge
                AI.
              </p>
              <img src={section_4} className="blog_image_top_bt" alt="" />
              <h3 className="font-section-sub-header-small-home">Choose KB </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You need to choose our knowledge base articles to build your
                Knowledge AI. Workativ Knowledge AI platform gives you the
                fastest way to upload the KBs of your choice.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  External KB can contain knowledge articles from third-party
                  services such as SharePoint, Notion, Zendesk, Google Drive,
                  Dropbox, Box, ServiceNow, and other third-party applications.
                  Using a connector, Workativ allows you to connect your
                  Knowledge AI with external KB resources.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Internal KB or Workativ KB provides access to internal
                  knowledge articles common to industry-specific issues such as
                  desktop blue screen, headphone sound issues, printer paper
                  jam, application software installation, license upgrading,
                  etc. Internal or Workativ KB can quickly answer users’
                  questions regarding internal processes.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Website KB is ideal for employees to find scattered
                  information on the website. Knowledge AI makes fetching
                  employee information across the website accessible, which is
                  otherwise quite confusing and time-consuming when maintained
                  with the traditional enterprise search.
                </li>
              </ul>
              <BlogCta
                ContentCta="Automate Employee Queries with Knowledge AI"
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />

              <h3 className="font-section-sub-header-small-home">
                Provide LLM-powered Knowledge AI access to KB
              </h3>
              <img src={section_5} className="blog_image_top_bt" alt="" />
              <p class="font-section-normal-text-testimonials line-height-18">
                The flexibility of the no-code platform of Workativ is that it
                does not require you to code to train the Knowledge AI platform
                extensively.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge bases you have ready to upload to train Knowledge AI
                can be easily connected with just a click.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can choose all knowledge base types to provide
                enterprise-wide custom response solutions to employee questions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Giving Knowledge AI access to separate KB types can help if
                specific needs or individual use cases exist.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Knowledge AI search integration
              </h3>
              <img src={section_6} className="blog_image_top_bt" alt="" />
              <p class="font-section-normal-text-testimonials line-height-18">
                All existing Workativ users know how to create dialog flows
                using various conversation templates in the chatbot builder.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI is the latest feature available in the dropdown
                menu. By leveraging this feature, you can easily apply the power
                of Generative AI or large language model capability in
                generating personalized and summarized answers to employee
                queries.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Simply put, the Knowledge AI feature can deliver relevant and
                accurate intent-based bot conversation flexibility between users
                and the bot for repetitive and common employee questions.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Implement agent handover.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                At some point, Knowledge AI may return a similar response that
                is not useful for solving a workspace problem for your
                employees. Agent handover is handy in making employee
                self-service more independent and convenient for autonomous
                problem-solving.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ conversational AI platform allows you to implement
                agent handover within the chatbot builder.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Deploy Knowledge AI bot.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Once you configure everything to apply Knowledge AI to
                personalize employee knowledge search and enable faster
                problem-solving autonomously, you can deploy your bot in the
                preferred channels.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is better to make the bot available where your employees are
                ─ Teams, Slack, or chat widget.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your conversational AI bot with the power of Knowledge AI is
                ready to use and transform employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As McKinsey says, tech-enabled employee development and
                resilience can add to organizational growth. It is right that
                giving your employees the ability to handle queries steadily
                through Knowledge AI is a phenomenon.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Implementing{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is a frictionless way to nurture employee experience, reduce
                friction from the self-service platform, and give more
                flexibility to allow them to address repetitive and common
                queries more independently and conveniently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ conversational AI is a cost-effective solution for
                organizations to harness the power of Generative AI and large
                language models, helping users fetch summarized, accurate, and
                relevant answers in no time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To gain the ability to level up your employees and optimize your
                bottom-line expenses, Workativ Knowledge AI is the right tool to
                reach your business objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  {" "}
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Challenges: Employee support limitations with traditional
                    KB searches.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Can large language models be a substitute for traditional
                    KB searches?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. What is Knowledge AI?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Introducing Knowledge AI search from Workativ
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How effective is Knowledge AI for employee support
                    enhancement?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. How to implement Knowledge AI with Workativ LLM-powered
                    conversational AI platform?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                ‘What’s the status of my request for a new laptop?’
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A help desk agent who has just joined a call is as clueless as
                you are.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s a long wait time for the requester until the agent searches
                for ticket history and returns with the correct status info.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What else?
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employees are less predictive about when they can have the
                  right device to work at their best.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee value is reduced.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Disengaged employees cost organizations lost productivity.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instantaneous, real-time, yet factual information is a tough
                challenge for organizations, forcing them to struggle to meet
                employee expectations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When thinking about employee resilience, tech-enabled capability
                building is the key to creating value for employees and
                businesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s a win-win for organizations when they focus on performance
                and people through tech investment. McKinsey & Co. predicts{" "}
                <a href="https://www.mckinsey.com/quarterly/the-five-fifty/five-fifty-employee-development-a-skills-based-approach?cid=other-eml-onp-mip-mck&hlkid=85f3fbae0d9249beb7ae7c24169b4e5d&hctky=14822067&hdpid=ffe42cd9-d1ff-41d5-972c-c6077c4d8f6e">
                  $1 billion in economic profit for organizations with a
                  perspective of employee resilience and development.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, organizations have long struggled to deploy the right
                tech-enabled capability and resorted to a predefined or
                rule-based chatbot to facilitate employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A chatbot with default replies can help with problems with known
                challenges 一 but with lots of effort from the users and help
                desk agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI and conversational AI can come together to speed up
                employee support, reduce the time to search for information and
                improve CSAT (employee satisfaction).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this article, we will talk about why it is time to switch to
                Knowledge AI for employee support rather than relying on regular
                chatbots for repetitive employee support and value addition.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs ">
                Challenges: Employee support limitations with traditional KB
                searches.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                How often do you look at the downsides of enterprise search
                systems or default reply-based chatbots?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Maybe never.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Information is scattered across various intrasystem and external
                databases. So, data lying in silos can be complex to find
                quickly and, more often, result in workspace productivity
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The pain points with an enterprise search system, such as
                chatbots, are strikingly significant.
              </p>
              <img src={section_1} className="blog_image_top_bt" alt="" />

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Incomplete insights into employee questions
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                KB articles are often limited in providing complete insights
                into employee questions.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-6 list-style-type-circle">
                <li className="ffont-section-normal-text-testimonials">
                  New information is often available but requires manual effort
                  to update the knowledge bases, a challenge for most
                  organizations with limited resources.
                </li>
                <li className="ffont-section-normal-text-testimonials">
                  Information surfaced in a question-answering window has a list
                  of suggestions in the form of articles, requiring users to
                  dedicate extended time for knowledge discovery.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  More time to build KBs
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Building knowledge bases for search systems or FAQs takes a huge
                chunk of time, bypassing the need to make changes to the
                existing content in KB articles and leading users to struggle to
                resolve problems, even with self-service functionality.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Less integration flexibility with content systems
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Traditional chatbots or enterprise search systems offer limited
                integration capabilities with content resources spread across
                enterprise-wide systems, hence limited knowledge discovery to
                offer the help employees need to resolve problems autonomously.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Limited problem-solving capability with built-in VAs
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Virtual agents or chatbots built within the service desk or help
                desk systems can offer service desk assistance but are limited
                to ticket creation and escalation to the various stages to tier
                agents. There is no straightforward or real-time autonomous help
                with many built-in chatbots.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-sub-header-small-home pl-4">
                <li className="font-section-sub-header-small-home">
                  Lack of personalized conversational experience
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Though employees want independent problem-solving capabilities,
                the human touch adds to a great conversational experience, most
                miss out on this opportunity with rudimentary chatbots or
                default-reply-based chatbots. With end-to-end conversations
                sounding interactive, users tend to adapt fast and connect more
                to solve the workspace problems autonomously.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Can large language models be a substitute for traditional KB
                searches?
              </h2>
              <img src={section_2} className="blog_image_top_bt" alt="" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Large language models are AI-powered natural language models
                with huge corpora of datasets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over-the-shelf LLMs such as ChatGPT or GPT-4 contain generic
                data to answer generic questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, when you combine your enterprise search systems or
                chatbots with LLMs, your employees can retrieve answers that
                everyone else can find for issues such as headphone sound
                troubleshooting, and application installation, to name a few.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Similar IT or employee support problems other enterprises have
                can fetch solutions to some length from LLM-powered chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Regarding specificity, meaning if users need a domain-specific
                solution to problems, LLMs alone cannot offer help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, An organization using a project management tool
                like Notion has specific data related to various sales
                operations, marketing, etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                An employee wants an update about a client’s project, say, a
                data migration project. Over-the-shelf LLM-powered chatbots can
                limit this expectation. On the other hand, LLMs integrate
                generic information, which can be wrongful, causing
                hallucinations and misinformation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI integration can be highly efficient to improve
                search functionalities and knowledge discovery to help with
                effective employee support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is Knowledge AI?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI, as the name suggests, refers to an information
                discovery capability built on top of generative artificial
                intelligence and conversational AI technologies to improve
                search performance through personalized and summarized knowledge
                discovery to enhance employee productivity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Often, structured FAQ-based knowledge falls short of employee
                support expectations to deliver help with common and repetitive
                questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI can fetch data from unstructured data grounded in
                KB articles within the platform and offer NLP responses to
                queries through intent detection, context, and entity
                extraction.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI offers custom NLP responses beyond pre-defined
                rules or keyword-based search results compared to traditional
                search platforms or chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As a result, Knowledge AI offers more personalized and
                conversational responses that help employees resolve their
                problems at scale.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Introducing Knowledge AI search from Workativ
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ brings Knowledge AI search integrations for its{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI platform
                </a>{" "}
                to allow users to leverage the benefits of large language model
                properties or generative AI capability to enhance the speed of
                capturing information, producing personalized and intent-based
                information for the repetitive and common user queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ provides a ChatGPT-like search experience that is more
                personalized and conversational for autonomous problem-solving
                in the enterprise setting.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                “Retrieving information from several knowledge bases or KB
                sources through connector-based integrations across disparate
                systems, such as SharePoint, Dropbox, Google Drive, Notion,
                etc., helps employees quickly find context-aware information and
                resolve common issues more efficiently”.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How effective is Knowledge AI for employee support enhancement?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                From a ChatGPT or LLM point of view, finding answers to queries
                is best for learning new skills or knowing something to solve a
                problem in an ongoing process.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As we said earlier, domain-specific issues need custom answers
                employees can use to automate and resolve problems
                independently.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI removes friction in the self-service capabilities,
                found with rule-based search systems or chatbots by tapping into
                various knowledge articles uploaded through Workativ or Internal
                KB, External KB, and Website KB.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It means knowledge search is not restricted to more than just a
                few article bases. Instead, it has an extensive language model
                database to augment the semantic search and deliver accurate and
                coherent generative answers to NLP-based common queries.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With Workativ Knowledge AI, users can remove the need to train
                the model as needed with custom solutions to be built with LLM
                models or GPT models from scratch.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Neither does it need fine-tuning nor prompt-engineering. All it
                takes is upload content to train, modify, or delete content so
                that users can always get up-to-date information and accelerate
                search performance via Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Also, Knowledge AI provides a closed domain to upload content
                and build your specific large language model to offer
                domain-specific answers with more robust security checks to
                avoid misinformation like the open-source training method,
                making it less vulnerable to hallucination and delivering biased
                and wrong information.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Take one example here. For security reasons, a user needs to
                know the VPN settings of his device while working from home. It
                could hamper his work if he doesn’t get the information in time.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A traditional search system could only provide articles about
                VPN setting issues. Unaware of which piece could provide an apt
                and accurate answer for the self-service, he might comb through
                lengthy articles and handle information in a silo.
              </p>
              <img src={section_3} className="blog_image_top_bt" alt="" />
              <p class="font-section-normal-text-testimonials line-height-18">
                However, Knowledge AI provides straightforward, accurate,
                summarized, and personalized versions of responses for any
                common and repetitive queries.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using Knowledge AI-powered self-service built in a{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  conversational AI chatbot,
                </a>{" "}
                a user can capture only necessary information presented in a
                summarized manner to help resolve the VPN setting issues.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to implement Knowledge AI with Workativ LLM-powered
                conversational AI platform?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ allows you to unlock the full potential of a no-code
                platform to enable you to build a conversational AI chatbot or
                FAQ-based bot by layering the properties of large language
                models or Generative AI through Knowledge AI.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Implementing Knowledge AI KB is just a few clicks of a task for
                existing users. If you already use a chatbot or FAQ bot, click
                Knowledge AI search from the drop-down menu next to a dialog
                template, and it will be implemented.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                However, You need to build your KBs to gain the flexibility of a
                large language model in generating personalized answers to
                common queries.
              </p>
              <p class="font-section-normal-text-testimonials-medium color-black mb-0">
                Here are the prerequisites to develop your LLM-powered Knowledge
                AI.
              </p>
              <img src={section_4} className="blog_image_top_bt" alt="" />
              <h3 className="font-section-sub-header-small-home">Choose KB </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You need to choose our knowledge base articles to build your
                Knowledge AI. Workativ Knowledge AI platform gives you the
                fastest way to upload the KBs of your choice.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  External KB can contain knowledge articles from third-party
                  services such as SharePoint, Notion, Zendesk, Google Drive,
                  Dropbox, Box, ServiceNow, and other third-party applications.
                  Using a connector, Workativ allows you to connect your
                  Knowledge AI with external KB resources.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Internal KB or Workativ KB provides access to internal
                  knowledge articles common to industry-specific issues such as
                  desktop blue screen, headphone sound issues, printer paper
                  jam, application software installation, license upgrading,
                  etc. Internal or Workativ KB can quickly answer users’
                  questions regarding internal processes.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Website KB is ideal for employees to find scattered
                  information on the website. Knowledge AI makes fetching
                  employee information across the website accessible, which is
                  otherwise quite confusing and time-consuming when maintained
                  with the traditional enterprise search.
                </li>
              </ul>
              <BlogCta
                ContentCta="Automate Employee Queries with Knowledge AI"
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />

              <h3 className="font-section-sub-header-small-home">
                Provide LLM-powered Knowledge AI access to KB
              </h3>
              <img src={section_5} className="blog_image_top_bt" alt="" />
              <p class="font-section-normal-text-testimonials line-height-18">
                The flexibility of the no-code platform of Workativ is that it
                does not require you to code to train the Knowledge AI platform
                extensively.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge bases you have ready to upload to train Knowledge AI
                can be easily connected with just a click.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can choose all knowledge base types to provide
                enterprise-wide custom response solutions to employee questions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Giving Knowledge AI access to separate KB types can help if
                specific needs or individual use cases exist.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Knowledge AI search integration
              </h3>
              <img src={section_6} className="blog_image_top_bt" alt="" />
              <p class="font-section-normal-text-testimonials line-height-18">
                All existing Workativ users know how to create dialog flows
                using various conversation templates in the chatbot builder.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI is the latest feature available in the dropdown
                menu. By leveraging this feature, you can easily apply the power
                of Generative AI or large language model capability in
                generating personalized and summarized answers to employee
                queries.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Simply put, the Knowledge AI feature can deliver relevant and
                accurate intent-based bot conversation flexibility between users
                and the bot for repetitive and common employee questions.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Implement agent handover.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                At some point, Knowledge AI may return a similar response that
                is not useful for solving a workspace problem for your
                employees. Agent handover is handy in making employee
                self-service more independent and convenient for autonomous
                problem-solving.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ conversational AI platform allows you to implement
                agent handover within the chatbot builder.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Deploy Knowledge AI bot.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Once you configure everything to apply Knowledge AI to
                personalize employee knowledge search and enable faster
                problem-solving autonomously, you can deploy your bot in the
                preferred channels.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is better to make the bot available where your employees are
                ─ Teams, Slack, or chat widget.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your conversational AI bot with the power of Knowledge AI is
                ready to use and transform employee experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As McKinsey says, tech-enabled employee development and
                resilience can add to organizational growth. It is right that
                giving your employees the ability to handle queries steadily
                through Knowledge AI is a phenomenon.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Implementing{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is a frictionless way to nurture employee experience, reduce
                friction from the self-service platform, and give more
                flexibility to allow them to address repetitive and common
                queries more independently and conveniently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ conversational AI is a cost-effective solution for
                organizations to harness the power of Generative AI and large
                language models, helping users fetch summarized, accurate, and
                relevant answers in no time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To gain the ability to level up your employees and optimize your
                bottom-line expenses, Workativ Knowledge AI is the right tool to
                reach your business objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  {" "}
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/knowledge-ai-employee-support"
              className="font-section-normal-text-testimonials"
            >
              How knowledge AI can improve the efficiency of the employee
              support team
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-knowledge-management-automation"
              className="font-section-normal-text-testimonials"
            >
              How to Use Generative AI to Automate Knowledge Management Tasks
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/implement-generative-ai-knowledge-base"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              How to Implement Generative AI with Knowledge base
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
